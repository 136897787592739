import React from 'react'
import { Text } from '@chakra-ui/react'

import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'



const IndexPage = () => {
  return (
    <Layout>
      <SEO title='Home' keywords={['composer', 'musician', 'web-developer']} />
      <StaticImage src='../../images/naama.jpg' alt='picture of the author' />
      <Text fontSize='xl'>
        Composer, Music technologist and occasional web developer based in
        Helsinki, Finland
      </Text>
      
    </Layout>
  )
}
export default IndexPage
